<template>
  <div />
</template>

<script>
import router from '@/router'
import AuthService from '@/auth/qonJwt/authService'

export default {
  name: 'Relogin',
  created() {
    const authService = new AuthService()
    authService.loginByHash(this.$route.params.hash)
      .catch(() => {
        console.dir(process.env.VUE_APP_OLD_PANEL_URL)
        window.location.href = process.env.VUE_APP_OLD_PANEL_URL
      })
      .then(tokenDataResponse => {
        let redirect = '/'
        if (tokenDataResponse.data.target.action) {
          redirect = tokenDataResponse.data.target.action
        }
        router.push(redirect)
      })
  },
}
</script>
